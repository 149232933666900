<messages>["./UserCreateUpdate"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw0>
    <v-col cols="12">
      <user-create-update
        :user-data="{ id }"
        @unsavedChanges="setUnsavedChanges"
        @success="onSuccess"
        @cancel="back"/>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import UserCreateUpdate from './components/UserCreateUpdate'

  import unsavedChangesHelper from '@/app/core/mixins/UnsavedChangesHelper'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import {mapMutations} from 'vuex'

  export default {
    name: 'UserEdit',

    components: {
      BaseLayout,
      UserCreateUpdate
    },

    mixins: [unsavedChangesHelper],

    props: {
      id: {
        type: [String, Number],
        required: true
      }
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),

      onSuccess (data) {
        this.addEvent ({
          type: 'success',
          message: this.$t ('updateSuccess', {link: data.username}),
          objects: [{
            name: data.username,
            link: {name: 'user.view', params: {id: data.userId}}
          }]
        })
        this.back ()
      },

      back () {
        this.$router.back ()
      }
    }
  }
</script>
